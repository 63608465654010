/* Global Styles */
.body {
  font-family: 'Poppins', sans-serif;
  background-color: #f0f0f0;
  color: #343a40;
  transition: all 0.3s ease;
}

/* Main Container */
.page-container {
  padding: 0 20px; /* Adjust padding for tablet and mobile */
  background-color: white; /* Set middle area to white */
  background-image: url('../images/traditional\ edited-final.png');
  background-repeat: no-repeat;
  background-size: 100% 100% ; /* width height*/
  background-position: 100%; 
  background-attachment: fixed; /* Fixed background on desktop */
}

/* Video Wrapper */
.video-wrapper {
  max-height: 290px;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Custom Navbar Styling */
.custom-navbar {
  background-color: maroon !important;
}

.navbar-brand, .nav-link {
  color: white !important;
  font-weight: bold;
}

.nav-link:hover {
  color: green !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Carousel */
.carousel {
  max-height: 500px;
}

.carousel-item img {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.carousel-item img:hover {
  transform: scale(1.05);
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #343a40;
}

/* Facilities */
.underline {
  text-decoration-style: solid;
  text-decoration-thickness: 4px;
  position: relative;
}

.facilities h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background-color: #ffcc00;
}

.facilities {
  padding: 30px 20px;
  background: linear-gradient(135deg, #e0e0e0, #ffffff);
  text-align: center;
}

.facility-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.facility {
  width: 45%;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #e0e0e0);
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.facility:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.facility-icon {
  color: #ffcc00;
  margin-bottom: 10px;
}

.facility h3 {
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #343a40;
}

.facility p {
  font-size: 1rem;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .facility {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .facility {
    width: 100%;
    margin: 10px 0;
  }
}



/* Gallery */
.gallery {
  padding: 30px 20px;
  background-color: #eceaea;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 0;
}

.gallery-grid a {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery-grid a:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.gallery-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 8px;
}

.gallery-grid a::before {
  content: attr(data-title);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}



.gallery-grid a:nth-child(1),
.gallery-grid a:nth-child(10) {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery-grid a:nth-child(3),
.gallery-grid a:nth-child(6) {
  grid-column: span 1;
  grid-row: span 2;
}

.gallery-grid a:nth-child(4) {
  grid-column: span 2;
  grid-row: span 1;
}

.gallery h2 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 2rem;
  color: #343a40;
  position: relative;
}

.gallery h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background-color: #ffcc00;
}

/* Testimonial Section Styling */
.testimonial {
  background: linear-gradient(135deg, rgba(240, 248, 255, 0.8), rgba(255, 255, 255, 0.9)), url('../images/image6.jpg') no-repeat center center/cover;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Decorative Overlays */
.testimonial::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.testimonial h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.testimonial h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #ffcc00, #ff9900);
  border-radius: 10px;
}

.testimonial-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 0px;
  margin: 0px;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  overflow: hidden;
  max-width: 5000px; /* Adjusted for smaller screens */
  margin-left: auto;
  margin-right: auto;
}

.testimonial-card::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  width: 80px;
  height: 80px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3), transparent);
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
}

.quote {
  font-size: 1.2rem;
  font-style: italic;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.5;
  position: relative;
  padding-left: 30px;
}

.quote::before {
  content: '“';
  font-size: 2rem;
  color: #ff9900;
  position: absolute;
  top: -10px;
  left: 5px;
}

.author {
  font-size: 1rem;
  font-weight: bold;
  color: #343a40;
}

/* Star Rating */
.star-rating {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.star-rating .star {
  font-size: 1rem;
  color: #ffcc00;
  margin: 0 1px;
}

.star-rating .star.empty {
  color: #e0e0e0;
}

/* Carousel Controls and Indicators */
.carousel-control-prev,
.carousel-control-next {
  filter: invert(1);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.carousel-indicators li {
  background-color: #ffcc00;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.carousel-indicators .active {
  background-color: #ff9900;
  width: 12px;
  height: 12px;
}

/* Testimonial Container */
.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  padding: 20px;
  scroll-snap-type: x mandatory;
}

.testimonial-container .testimonial-card {
  flex: 1;
  max-width: 30%;
  scroll-snap-align: center;
}

.testimonial-container.large-content .testimonial-card {
  flex: 1 0 100%;
}

.testimonial-container.medium-content .testimonial-card {
  flex: 1 0 48%;
}

.testimonial-container.small-content .testimonial-card {
  flex: 1 0 30%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .testimonial-card {
    max-width: 280px;
  }

  .testimonial h2 {
    font-size: 2.2rem;
  }

  .quote {
    font-size: 1.1rem;
  }

  .author {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .testimonial-card {
    max-width: 260px;
  }

  .testimonial h2 {
    font-size: 2rem;
  }

  .quote {
    font-size: 1rem;
  }

  .author {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .testimonial {
    padding: 60px 10px;
  }

  .testimonial-card {
    padding: 15px;
    margin: 10px;
    max-width: 90%;
  }

  .quote {
    font-size: 0.9rem;
  }

  .author {
    font-size: 0.8rem;
  }

  .testimonial h2 {
    font-size: 1.8rem;
  }

  .star-rating .star {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .testimonial {
    padding: 40px 10px;
  }

  .testimonial-card {
    max-width: 100%;
    margin: 10px auto;
  }

  .quote {
    font-size: 0.8rem;
  }

  .author {
    font-size: 0.7rem;
  }

  .testimonial h2 {
    font-size: 1.6rem;
  }

  .star-rating .star {
    font-size: 0.8rem;
  }
}


/* Contact */
.contact h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background-color: #ffcc00;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f4f4;
  padding: 20px;
}

.contact-left {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contact-left-title h2 {
  font-weight: 500;
  color: #2a2929;
  font-size: 2rem;
  margin-bottom: 5px;
  text-align: center;
}

.contact-left-title hr {
  border: #343a40;
  width: 120px;
  height: 5px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.contact-inputs,
.contact-left-textarea {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border: none;
  outline: none;
  padding-left: 25px;
  font-weight: 500;
  color: #0f0f0f;
  border-radius: 50px;
}

.contact-left-textarea {
  height: 140px;
  padding-top: 15px;
  border-radius: 20px;
}

.contact-inputs:focus {
  border: 2px solid #ffcc00;
}

.contact-inputs::placeholder {
  color: #a9a9a9;
}

.contact-left button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #fff;
  gap: 10px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(270deg, rgb(245, 79, 107), #f59048, #ffcc00);
  cursor: pointer;
}

.contact-left button img {
  height: 15px;
}

.contact-right img {
  width: 60px;
  padding-bottom: 20px;
  margin-left: 60px;
}

@media (min-width: 801px) {
  .page-container {
    padding-left: 200px;
    padding-right: 200px;
  }

  .contact-container {
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
  }

  .contact-left {
    max-width: 50%;
    align-items: flex-start;
  }

  .contact-right {
    display: block;
  }
}

/* Map */
.map h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left:  50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background-color: #ffcc00;
}

.map {
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.map iframe {
  border: none;
  border-radius: 8px;
  width: 100%;
}

/* Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section {
  animation: fadeInUp 0.5s ease-in-out;
}


/* Custom media queries for responsive font sizes */

@media (max-width: 1200px) {
  .facilities h2,
  .gallery h2,
  .map h2,
  .contact h2 {
    font-size: 1rem; /* Adjust font size for medium screens */
  }

  .contact-left-title h2 {
    font-size: 1.5rem; /* Adjust font size for medium screens */
  }

  .gallery h2 {
    font-size: 1.8rem; /* Adjust font size for medium screens */
  }
}

@media (max-width: 800px) {
  .page-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .facilities h2,
  .gallery h2,
  .map h2,
  .contact h2 {
    font-size: 1.8rem; /* Adjust font size for small screens */
  }

  .contact-left-title h2 {
    font-size: 1.6rem; /* Adjust font size for small screens */
  }

  .gallery h2 {
    font-size: 1.6rem; /* Adjust font size for small screens */
  }
}

@media (max-width: 600px) {
  .facilities h2,
  .gallery h2,
  .map h2,
  .contact h2 {
    font-size: 1.5rem; /* Adjust font size for extra small screens */
  }

  .contact-left-title h2 {
    font-size: 1.4rem; /* Adjust font size for extra small screens */
  }

  .gallery h2 {
    font-size: 1.4rem; /* Adjust font size for extra small screens */
  }
}

@media (max-width: 400px) {
  .facilities h2,
  .gallery h2,
  .map h2,
  .contact h2 {
    font-size: 1.3rem; /* Adjust font size for very small screens */
  }

  .contact-left-title h2 {
    font-size: 1.2rem; /* Adjust font size for very small screens */
  }

  .gallery h2 {
    font-size: 1.2rem; /* Adjust font size for very small screens */
  }
}
.navbar-brand img {
  max-width: 60px; /* Adjust the maximum width as per your design */
  height: auto; /* Maintain aspect ratio */
  margin-top: -10px; /* Adjust vertical alignment */
}


/* Footer styling */


.footer {
  position: relative; /* Ensure relative positioning for contained elements */
  width: 100%; /* Ensure full width */
}

.footer-video-container {
  position: relative; /* Ensure relative positioning for contained elements */
  width: 100%; /* Ensure full width */
  overflow: hidden; /* Hide any overflowing content */
}

.footer-video {
  width: 100%; /* Ensure the video fills the container */
  height: auto; /* Allow the video to maintain its aspect ratio */
  display: block; /* Ensure it behaves like a block element */
}

@media (max-width: 768px) {
  .footer-video {
    height: auto; /* Adjust height as needed for smaller screens */
  }
}

.running-line {
  background-color: #ffffff; /* White background */

}

.running-line-text {
  color: #28a745; /* Green text color */
  font-weight: bold; /* Bold font weight */
  height: 30px; /* Adjust the height of the running line */
  line-height: 30px; /* Center the text vertically */
}

@keyframes example {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
